<template>
    <h1 style="margin : 30px;">Redirecting to application download page</h1>
</template>
<script>
export default {
    mounted() {
        window.location.href =
            'https://apps.apple.com/mm/app/pyae-sone-shin/id1570012216';
    },
};
</script>
